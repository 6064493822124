import React from "react";
import "../styles/About.css";
import { Link } from "react-router-dom";
import year2023 from "../assets/2023.png";
import year2022 from "../assets/2022.png";
import year2021 from "../assets/2021.png";
import year2020 from "../assets/2020.png";
import year2019 from "../assets/2019.png";
import year2018 from "../assets/2018.png";
import year2017 from "../assets/2017.png";

function About() {
  return (
    <div className="about">
      <h1 className="about__title">О ПРОЕКТЕ</h1>

      <div className="about__description">
        <p>
          <b>VIII Кубок CTF России</b> — один из крупнейших турниров страны по информационной безопасности в формате CTF среди команд высших, средних профессиональных и общеобразовательных учреждений. Также в состязаниях могут участвовать молодые специалисты в возрасте до 27 лет.
        </p>
        <p>
          <b>Кубок CTF России</b> — это шанс для молодых талантов заявить о себе и продемонстрировать навыки по защите киберпространства. Турнир является площадкой для выявления новых звезд кибербезопасности и помогает определить сильнейшие команды, способные противостоять современным киберугрозам.
        </p>


      <h2 className="about__subtitle">КОНТАКТЫ</h2>

        <p className="about__contacts">
    <a href="mailto:info@aciso.ru">info@aciso.ru</a> — для организационных вопросов,<br/>
    <a href="mailto:zoi@aciso.ru">zoi@aciso.ru</a> — для партнеров и спонсоров,<br/>
    <a href="mailto:pr@aciso.ru">pr@aciso.ru</a> — для СМИ.
  </p>
      </div>

      <h2 className="about__subtitle">ИСТОРИЯ МЕРОПРИЯТИЯ</h2>

      <div className="about__history">
        <div className="about__year">
          <img src={year2023} alt="2023" className="about__year-image"/>
          <div className="about__year-content">
            <div className="about__text">
              <h3>ТЕКСТ</h3>
              <ul>
                <li><a href="https://dzen.ru/a/ZaTfTur8P2mqCdJo" target="_blank">ОТЧЕТ</a></li>
                <li>ЛЕГЕНДА</li>
              </ul>
            </div>
            <div className="about__media">
              <h3>МЕДИА</h3>
              <ul>
                <li><a href="https://vk.com/album-153821859_300512832" target="_blank">ФОТО</a></li>
                <li><a href="https://vk.com/video-153821859_456239052" target="_blank">ВИДЕО</a></li>
                <li><a href="https://vk.com/video-153821859_456239049" target="_blank">ТРАНСЛЯЦИЯ ПОЛУФИНАЛА</a></li>
                <li><a href="https://vk.com/video-153821859_456239050" target="_blank">ТРАНСЛЯЦИЯ ФИНАЛА</a></li>
              </ul>
            </div>
            <div className="about__tasks">
              <h3>ТАСКИ</h3>
              <ul>
                <li><a href="https://github.com/C4T-BuT-S4D/ctfcup-2023-igra" target="_blank">FINAL</a></li>
                <li><a href="https://github.com/C4T-BuT-S4D/ctfcup-2023-ad" target="_blank">SEMIFINAL</a></li>
                <li><a href="https://github.com/C4T-BuT-S4D/ctfcup-2023-casino" target="_blank">SCHOOL FINAL</a></li>
                <li><a href="https://github.com/C4T-BuT-S4D/ctfcup-2023-quals" target="_blank">OPEN QUALS</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="about__year">
          <img src={year2022} alt="2022" className="about__year-image"/>
          <div className="about__year-content">
            <div className="about__text">
              <h3>ТЕКСТ</h3>
              <ul>
                <li><a href="https://dzen.ru/a/Y9vhNjT9zm1_XYTr" target="_blank">ОТЧЕТ</a></li>
                <li><a href="https://vk.com/@ctfcup-legenda-goroda-ensk" target="_blank">ЛЕГЕНДА</a></li>
              </ul>
            </div>
            <div className="about__media">
              <h3>МЕДИА</h3>
              <ul>
                <li><a href="https://vk.com/album-153821859_289970572" target="_blank">ФОТО</a></li>
                <li><a href="https://vk.com/video-153821859_456239047" target="_blank">ВИДЕО</a></li>
                <li><a href="https://vk.com/video-153821859_456239043" target="_blank">ТРАНСЛЯЦИЯ ПОЛУФИНАЛА</a></li>
                <li><a href="https://vk.com/video-153821859_456239046" target="_blank">ТРАНСЛЯЦИЯ ФИНАЛА</a></li>
              </ul>
            </div>
            <div className="about__tasks">
              <h3>ТАСКИ</h3>
              <ul>
                <li><a href="https://github.com/HackerDom/ctfcup-2022-battles" target="_blank">FINAL</a></li>
                <li><a href="https://github.com/C4T-BuT-S4D/ctfcup-2022-stage2" target="_blank">SEMIFINAL</a></li>
                <li><a href="https://github.com/acisoru/ctfcup22-quals" target="_blank">OPEN QUALS</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="about__year">
          <img src={year2021} alt="2021" className="about__year-image"/>
          <div className="about__year-content">
            <div className="about__text">
              <ul>
                <li><a href="https://dzen.ru/a/Yb81Mqz-FDu0lfhe" target="_blank">ОТЧЕТ</a></li>
                <li><a href="https://www.flickr.com/photos/130734273@N02/albums/72177720296276494" target="_blank">ФОТО</a></li>
              </ul>
            </div>
            <div className="about__media">
              <ul>
              <li><a href="https://github.com/acisoru/ctfcup-21-quals" target="_blank">ТАСКИ</a></li>
                <li><a href="https://www.youtube.com/playlist?list=PLYik5sOloUPYq529_WwlG-SA2a9mGjyJp" target="_blank">ВИДЕО</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="about__year">
  <img src={year2020} alt="2020" className="about__year-image"/>
  <div className="about__year-content">
    <div className="about__text">
      <ul>
        <li><a href="https://dzen.ru/a/YZd-ssGFny-yhU9a" target="_blank">ОТЧЕТ</a></li>
        <li><a href="https://www.flickr.com/photos/130734273@N02/albums/72157720131178164" target="_blank">ФОТО</a></li>
      </ul>
    </div>
    <div className="about__media">
      <ul>
        <li><a href="https://github.com/acisoru/ctfcup-2020-quals" target="_blank">ТАСКИ</a></li>
        <li><a href="https://www.youtube.com/playlist?list=PLYik5sOloUPYh0_QRKG7mnB1rO7_D7tfp" target="_blank">ВИДЕО</a></li>
      </ul>
    </div>
  </div>
</div>

<div className="about__year">
  <img src={year2019} alt="2019" className="about__year-image"/>
  <div className="about__year-content">
    <div className="about__text">
      <ul>
        <li><a href="https://dzen.ru/a/XftkzpUV7vEwefJ_" target="_blank">ОТЧЕТ</a></li>
        <li><a href="https://www.flickr.com/photos/130734273@N02/albums/72157716217278888" target="_blank">ФОТО</a></li>
      </ul>
    </div>
    <div className="about__media">
      <ul>
        <li><a href="https://github.com/ctfcup/2019-task-based" target="_blank">ТАСКИ</a></li>
        <li><a href="https://www.youtube.com/playlist?list=PLYik5sOloUPbsu1D9PPPL4cvW8kFPdmmc" target="_blank">ВИДЕО</a></li>
      </ul>
    </div>
  </div>
</div>

<div className="about__year">
  <img src={year2018} alt="2018" className="about__year-image"/>
  <div className="about__year-content">
    <div className="about__text">
      <ul>
        <li><a href="https://ctfnews.ru/news/1478" target="_blank">ОТЧЕТ</a></li>
        <li><a href="https://www.flickr.com/photos/130734273@N02/albums/72157704264017254" target="_blank">ФОТО</a></li>
      </ul>
    </div>
    <div className="about__media">
      <ul>
        <li><a href="https://rutube.ru/video/ff40d1d954c9c30d03de9ba20da37a83/" target="_blank">ВИДЕО</a></li>
        <li><a href="https://www.youtube.com/watch?v=JCS9Ffnt91g" target="_blank">ТРАНСЛЯЦИЯ</a></li>
      </ul>
    </div>
  </div>
</div>

<div className="about__year">
  <img src={year2017} alt="2017" className="about__year-image"/>
  <div className="about__year-content">
    <div className="about__text">
      <ul>
        <li><a href="https://ctfnews.ru/news/1346" target="_blank">ОТЧЕТ</a></li>
        <li><a href="https://www.flickr.com/photos/130734273@N02/albums/72157691490917935" target="_blank">ФОТО</a></li>
      </ul>
    </div>
  </div></div>
      </div>
    </div>
  );
}
export default About;