import React from "react";
import "../styles/Footer.css";
import { Link } from "react-router-dom";
import logo from "../assets/logo-blue.png";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__content">
          <div className="footer__left">
            <div className="footer__contacts">
              <h2 className="footer__title">КОНТАКТЫ</h2>
              <p>
                tg:
                <Link to="https://t.me/deviantwish" target="_blank">
                  @deviantwish
                </Link>
                <span className="footer__contacts--comma">,</span>
                <Link to="https://t.me/zoi345" target="_blank">
                  @zoi345
                </Link>
              </p>
              <p>
                phone:
                <Link to="tel:+74956405330" target="_blank">
                  +7(495)640-53-30
                </Link>
              </p>
              <p>
                e-mail:
                <Link to="mailto:info@aciso.ru" target="_blank">
                  info@aciso.ru
                </Link>
              </p>
            </div>
            <div className="footer__logo-item">
              <Link to="/">
                <img src={logo} alt="CTF Cup" className="footer__logo-image" />
              </Link>
            </div>
          </div>
          <div className="footer__right">
            <div className="footer__decorative-blocks footer__decorative-blocks--small">
              <div className="footer__block footer__block--small"></div>
            </div>
            <div className="footer__decorative-blocks footer__decorative-blocks--large">
              <div className="footer__block footer__block--large footer__block--red"></div>
            </div>
            <div className="footer__buttons">
              <div className="footer__logos">
                <Link to="https://vk.com/ctfcup" target="_blank">
                  <div className="footer__logo footer__logo--cybersecurity"></div>
                </Link>
                <Link to="https://t.me/ctfcup24" target="_blank">
                  <div className="footer__logo footer__logo--ctf"></div>
                </Link>
              </div>
              <div className="footer_logo--link">
                <Link to="/about">
                  <p className="footer__ctf-text">
                    О ПРОЕКТЕ
                  </p>
                </Link>
              </div>
            </div>
            <div className="footer__decorative-blocks footer__decorative-blocks--small footer__decorative-blocks--end">
              <div className="footer__block footer__block--small"></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
