import React from "react";
import "../styles/Registration.css";
import { Link } from "react-router-dom";
import result from "../assets/result.png";
import result_mobile from "../assets/result_mobile.png";

function ResultsTable() {
  return (
    <div className="registration-ru">
      <h1>ПОБЕДИТЕЛИ<br /> OPEN QUALS</h1>
      <div className="registration-form-container">
        <div className="registration-text">
          По результатам Отборочного этапа стали известны команды-полуфиналисты,
          которые сразятся на очных этапах за звание победителей Кубка CTF
          России.
        </div>
        <div className="result">
          <img src={result} />
        </div>
        <div className="result_mobile">
          <img src={result_mobile} />
        </div>
      </div>
    </div>
  );
}

export default ResultsTable;
