import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/Home.css";
import positionsPdf from "../assets/positions.pdf";
import regulationsPdf from "../assets/regulations.pdf";
import raspisanie from "../assets/raspisanie.png";
import live from "../assets/live.png";

function Home() {
  useEffect(() => {
    // Create script element
    const script = document.createElement("script");
    script.src =
      "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Af85d74fc89075508ca0ce207d23819a506dcdaee75cee0ba67e8850ecf39c481&width=344&height=253&lang=ru_RU&scroll=true";
    script.async = true;

    // Add script to place__description div
    const mapContainer = document.querySelector(".place__description");
    mapContainer.appendChild(script);

    // Cleanup
    // return () => {
    //   mapContainer.removeChild(script);
    // };
  }, []);

  return (
    <div className="home">
      <h1 className="home__brand-name">КУБОК CTF</h1>
      <div className="home__brand-year">2024</div>
      <div className="home__description">
        <section className="registration-block">
          <div className="registration">
            <Link to="/results">
              <img src={live} className="registration-block_img" />
            </Link>
          </div>
        </section>

        <section className="place">
          <div className="place__description">
            <div id="map-container"></div>
          </div>
        </section>

        <section className="documents">
          <h2 className="documents__title">
            ОФИЦИАЛЬНЫЕ <br />
            ДОКУМЕНТЫ
          </h2>
          <div className="documents__items">
            <div className="documents__item">
              <a
                href={positionsPdf}
                target="_blank"
                rel="noopener noreferrer"
                className="documents__item--icon-link"
              >
                <div className="documents__item--icon documents__item--icon-red"></div>
              </a>
              <h3 className="documents__item--title">Положение</h3>
              <div className="documents__item--description">
                О проведении Всероссийских соревнований в области информационной
                безопасности «Кубок CTF России»
              </div>
            </div>
            <div className="documents__item">
              <a
                href={regulationsPdf}
                target="_blank"
                rel="noopener noreferrer"
                className="documents__item--icon-link"
              >
                <div className="documents__item--icon documents__item--icon-blue"></div>
              </a>
              <h3 className="documents__item--title">Регламенты</h3>
              <div className="documents__item--description">
                Правила проведения отборочного этапа VIII Кубка CTF России
              </div>
            </div>
            <div className="documents__item">
              <a
                href={raspisanie}
                target="_blank"
                rel="noopener noreferrer"
                className="documents__item--icon-link"
              >
                <div className="documents__item--icon documents__item--icon-red"></div>
              </a>
                <h3 className="documents__item--title">РАСПИСАНИЕ</h3>
                <div className="documents__item--description">
                  Расписание очных этапов <br /> VIII Кубка CTF России
                </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
