import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/Header.css";
import logo from "../assets/logo.png";

function Header() {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const [isSubdomain, setIsSubdomain] = useState(false);
  const [showLegend, setShowLegend] = useState(false);

  useEffect(() => {
    const currentHost = window.location.hostname;
    setIsSubdomain(currentHost === "register.ctfcup.ru");

    const checkDate = () => {
      const currentDate = new Date();
      const targetDate = new Date('2024-10-26T09:00:00');
      setShowLegend(currentDate >= targetDate);
    };

    checkDate();
    const timer = setInterval(checkDate, 60000); // Check every minute

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
      if (currentScrollPos > prevScrollPos) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header
      className={`header ${visible ? "header--visible" : "header--hidden"}`}
    >
      <div className="header__content">
        {isSubdomain ? (
          <a href="https://ctfcup.ru" className="header__logo-link">
            <img src={logo} alt="CTF Cup" className="header__logo" />
          </a>
        ) : (
          <Link to="/" className="header__logo-link">
            <img src={logo} alt="CTF Cup" className="header__logo" />
          </Link>
        )}

        <Link to="/results" className="header__registration">
          ТРАНСЛЯЦИЯ
        </Link>
        <button className="header__menu-toggle" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <nav className={`header__nav ${isMenuOpen ? "header__nav--open" : ""}`}>
          {isSubdomain ? (
            <>
              <a href="https://ctfcup.ru" className="header__nav-link">
                Главная
              </a>
              <a
                href="https://ctfcup.ru/information"
                className="header__nav-link"
              >
                Информация
              </a>
              <a href="https://ctfcup.ru/team" className="header__nav-link">
                Команда
              </a>
              <a href="https://ctfcup.ru/jury" className="header__nav-link">
                Жюри
              </a>
              <a href="https://ctfcup.ru/partners" className="header__nav-link">
                Партнёры
              </a>
              {showLegend && (
                <a href="https://ctfcup.ru/legend" className="header__nav-link">
                  Легенда
                </a>
              )}
            </>
          ) : (
            <>
              <Link to="/" className="header__nav-link">
                Главная
              </Link>
              <Link to="/information" className="header__nav-link">
                Информация
              </Link>
              <Link to="/team" className="header__nav-link">
                Команда
              </Link>
              <Link to="/jury" className="header__nav-link">
                Жюри
              </Link>
              <Link to="/partners" className="header__nav-link">
                Партнёры
              </Link>
              {showLegend && (
                <Link to="/legend" className="header__nav-link">
                  Легенда
                </Link>
              )}
            </>
          )}
        </nav>
      </div>
      <div className="header__line"></div>
    </header>
  );
}

export default Header;