import React from "react";
import "../styles/Results.css";

function Results() {
  return (
    <div className="results">
      <div className="results__header">
        <h1 className="results__title">ТРАНСЛЯЦИЯ</h1>
      </div>

      <div className="results__content">
        <p className="results__description">
          Наблюдай за соревнованиями команд в режиме реального времени!
        </p>

        <div className="results__stages">
          <div className="results__stage">
            <h2 className="results__stage-title">20 ДЕКАБРЯ. ШКОЛЬНЫЙ ФИНАЛ</h2>
            <div className="results__video-wrapper">
              <iframe 
                src="https://vk.com/video_ext.php?oid=-153821859&id=456239053&autoplay=1" 
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className="results__stage">
            <h2 className="results__stage-title">21 ДЕКАБРЯ. ПОЛУФИНАЛ</h2>
            <div className="results__video-wrapper">
              <iframe
                src="https://vk.com/video_ext.php?oid=-153821859&id=456239054&autoplay=1"
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className="results__stage">
            <h2 className="results__stage-title">22 ДЕКАБРЯ. ФИНАЛ</h2>
            <div className="results__video-wrapper">
              <iframe
                src="https://vk.com/video_ext.php?oid=-153821859&id=456239055&autoplay=1"
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Results;